h1 {
    font-size: 40px;
    color: #4B4B4B;
    margin-top:50px !important;
}

.buttonsHome {
    display: flex;
    justify-content: center;
}

.bigButtons {
    display:flex;
    justify-content: center;
}

@media screen  and (max-width: 768px){
    .bigButtons {
        display:block;
        text-align: center;
    }

    .buttonsHome {
        display: inline-block;
    }
}