.cartesListing {
    text-align: center;
    padding:0 15px;
}

.cartesListing h2{
    margin-top:30px;
}

.cartesListing .cartesListingButton {
    margin-bottom: 10px;
}

.customerRegister h3 {
    background-color:#F3931E;
    color:white;
    text-align:center;
    padding:10px 0;
    margin-top:10px;
}

.formCustomer {
    padding:0 15px;
}

.errorMessage {
    color:red;
}

.checkboxAgreement {
    vertical-align: middle !important;
}

.labelAgreement {
    display:inline !important;
    font-weight:normal !important;
    margin-left:5px !important;
    vertical-align: middle;
}

.validationMessage {
    color:green;
}

.submit {
    text-align: center;
}

.submit button {
    height:auto;
}