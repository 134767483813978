@font-face {
    font-family: 'Montserrat';
    font-weight: normal;
    src: url('../public/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: bold;
    src: url('../public/fonts/Montserrat/Montserrat-Bold.ttf');
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: rgb(245, 245, 246);
}

p {
    font-family: 'Montserrat', sans-serif;
}

ul {
    margin: 0;
    padding: 0
}

ul li {
    list-style: none;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

[class*="col-"] {
    width: 100%;
}

.container-fluid {
    max-width: 100%;
}

.global {
    min-height:74vh;
}

@media only screen and (max-width: 767px) {
    h1 {
        text-align: center;
        font-size: 30px;
    }

    .ButtonHome {
        margin-right: 0 !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ButtonHome p {
        font-size: 26px;
    }

    .qrCodePart {
        text-align: center;
    }
}

@media only screen and (min-width: 768px) {

    .container {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    [class*="col-"] {
        float: left;
    }

    .col-1 {
        max-width: 8.33%;
    }

    .col-2 {
        max-width: 16.66%;
    }

    .col-3 {
        max-width: 25%;
    }

    .col-4 {
        max-width: 33.33%;
    }

    .col-5 {
        max-width: 41.66%;
    }

    .col-6 {
        max-width: 50%;
    }

    .col-7 {
        max-width: 58.33%;
    }

    .col-8 {
        max-width: 66.66%;
    }

    .col-9 {
        max-width: 75%;
    }

    .col-10 {
        max-width: 83.33%;
    }

    .col-11 {
        max-width: 91.66%;
    }

    .col-12 {
        max-width: 100%;
    }

    .offset-1 {
        margin-left: 8.33%;
    }

    .offset-2 {
        margin-left: 16.66%;
    }

    .offset-3 {
        margin-left: 25%;
    }

    .offset-4 {
        margin-left: 33.33%;
    }

    .offset-5 {
        margin-left: 41.66%;
    }

    .offset-6 {
        margin-left: 50%;
    }

    .offset-7 {
        margin-left: 58.33%;
    }

    .offset-8 {
        margin-left: 66.66%;
    }

    .offset-9 {
        margin-left: 75%;
    }

    .offset-10 {
        margin-left: 83.33%;
    }

    .offset-11 {
        margin-left: 91.66%;
    }

    .offset-12 {
        margin-left: 100%;
    }
}

.max-w-100 {
    max-width: 100%;
}

.max-w-100-important {
    max-width: 250px !important;
}

.addCarte {
    padding-right:15px;
}

.noCarte {
    text-align: center;
    margin-top:50px !important;
}

table th {
    /*color:#F29200 !important;*/
    font-size:18px !important;
}

@media only screen and (max-width: 767px) {
    #tableHead{
        display:table-header-group !important;
    }

    #tableHead tr{
        display:table-row !important
    }

    #tableHead tr th{
        display:table-cell !important;
        padding-top:15px !important;
        padding-bottom:15px !important;
    }

    #tableHead tr th:nth-child(1){
        width:32% !important;
    }

    #tableBody {
        display:table-row-group !important;
    }

    #tableBody tr {
        display:table-row !important;
    }

    #tableBody tr td {
        display:table-cell !important;
        font-size:12px;
    }

    #tableBody tr td button {
        margin-bottom:5px;
        font-size:12px;
    }

    .addCarte, .qrCodePart {
        padding:0 15px;
    }
}


