

footer {
    background-color:white;
    padding-top:5px;
    padding-bottom:5px;
    margin-top:30px;
}

footer .footer-logo{
    display:flex;
    align-items: center;
}

footer .footer-logo.public {
    margin:auto;
}

footer .footer-logo span{
    padding-right:10px;
}

footer .footer-logo img{
    max-width:150px;
}

footer .contact {
    text-align:right;
    display:flex;
    align-items: center;
    justify-content: flex-end;
}

footer .contact a, footer .publicLegals a{
    text-decoration: underline;
}

footer .publicLegals{
    justify-content: center;
}

footer .publicLegals{
    text-align: center;
}

@media only screen and (max-width: 767px) {
    footer .contact {
        text-align: center;
    }

    footer .contact p {
        margin:auto;
    }

    footer .footer-logo span{
        display: none;
    }

    footer .footer-logo a{
        margin:auto;
    }

}