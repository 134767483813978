.legalsPage h2 {
    color:#F29200;
}

.legalsPage a {
    color:black;
    text-decoration: underline;
}

.legalsPage .legalsLeft{
    padding-right:15px;
}

.legalsPage .legalsRight{
    padding-left:15px;
}


@media only screen and (max-width: 767px) {
    .legalsPage .legalsLeft{
        padding:15px;
    }

    .legalsPage .legalsRight{
        padding:15px;
    }

}