.callback {
    text-align: center;
}

.callback img {
    width:100px;
    margin:30px 0;
}

.callback .accountValidation {
    color:#f39200;
    font-weight:bold;
    font-size:28px;
    max-width:800px;
    margin:auto;
}

.callback-return p {
    display:none;
}

.callback p {
    display:block;
}