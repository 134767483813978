nav {
    background-color:white;
}

.logo {
    /*margin-top:5px;*/
    /*margin-bottom:5px;*/
    margin:5px auto;
}

.logo img{
    max-width:150px;
}

.logoPublic {
    /*margin-top:5px;*/
    /*margin-bottom:5px;*/
    margin:5px auto;
    text-align: center;
}

.logoPublic img{
    max-width:150px;
}

.navList {
    display:flex;
    align-items: center;
    justify-content: flex-end;
}

.navItem:nth-child(1) {
    /*margin-right:50px;*/
}

.navItem{
    margin-left:15px;
}

.navItem {
    font-size: 15px;
    color: #4B4B4B;
    font-weight: bold;
}

.bannerControl {
    background-color:#F29200;
    color:white;
    padding:10px 0 10px 0;
}

.bannerControl p {
    font-weight:bold;
    font-size:18px;
    margin:0;
}

.bannerControl .bannerFlex {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .logo {
        text-align: center;
    }

    .navItem {
        margin:auto;
        margin-bottom:15px;
    }
}

.navItem .item{
    border-radius: 25.5px;
    border:2px solid white;
    transition: all ease-in-out .2s;
    padding:10px;
    color:#525252;
}

.navItem .item:hover{
    border-radius: 25.5px;
    border:2px solid #8b84c7;
    color:#8b84c7;
}

.navItem .connection {
    background: #3320CC;
    border-radius: 25.5px;
    color: #FFFFFF;
    height:42px;
    transition: all ease-in-out .2s;
    padding:10px 15px;
}

.navItem .connection:hover, .navItem .connection:active {
    background: #8b84c7 !important;
    color: #FFFFFF !important;
}

.navItem .deconnection {
    background: #cc2020;
    border-radius: 25.5px;
    color: #FFFFFF;
    height:42px;
    transition: all ease-in-out .2s;
    padding:10px 15px;
}

.navItem .deconnection:hover {
    background: #c97676;
    color: #FFFFFF;
}

.navItem .connection img {
    vertical-align: middle;
    margin-right:5px;
}

@media screen  and (max-width: 768px){
    .navItem .item{
        border:0;
    }

    .navItem .item:hover{
        border:0;
    }

}
