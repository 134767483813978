.ButtonHome {
    background-image: linear-gradient(180deg, #9195FF 0%, #722AD9 100%);
    border-radius: 59px;
    width:320px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    height: 390px;
    transition: all ease-in-out .2s;
}

.ButtonHome:hover {
    box-shadow: inset 0 0 10px #000000;
}

.ButtonHome:nth-child(1) {
    margin-right:30px;
}

.ButtonHome p {
    color:white;
    font-size: 33px;
    font-weight: bold;
    padding: 30px 0 0 0;
}